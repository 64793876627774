import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import BackButton from '../components/ui/BackButton';
import Button from '../components/ui/Button';
import Input from '../components/ui/Input';
import InputGroupRemovable from '../components/ui/InputGroupRemovable';
import Label from '../components/ui/Label';
import StepperLayout from '../components/layouts/StepperLayout';
import UniversalInputGenerator from '../interfaces/UniversalInputGenerator';
import {
  getControlledLoadUsage,
  setControlledLoadUsage,
} from '../utils/localStorage/localStorageFunctions';
import { ControlledLoadUsageLsType } from '../utils/localStorage/localStorageTypes';
import { navigateAndSetActiveStep } from '../utils/utils';
import { StepUrl } from '../utils/enums';
import usePreloadPageData from '../hooks/usePreloadPageData';
import SEOLayout from '../components/layouts/SEOLayout';

const ControlledLoadInputSchema = Yup.number()
  .typeError('Usage must be a number')
  .min(0.01, 'Usage must be more than 0')
  .max(10_000, 'Please check the usage amount');

const ControlledLoadSchema = Yup.object().shape({
  load1: ControlledLoadInputSchema.required('Please, specify the usage amount'),
  load2: ControlledLoadInputSchema,
  load3: ControlledLoadInputSchema,
});

const ControlledLoadUsage = () => {
  const inputsLimit = 3;

  const [inputs, setInputs] = useState<UniversalInputGenerator[]>(() => {
    const inputsArr = [
      {
        label: 'Controlled load usage',
        id: 'controlled-load-usage-1',
        name: 'load1',
        index: 1,
        removable: false,
      },
    ];

    const { controlledLoadUsage2, controlledLoadUsage3 } =
      getControlledLoadUsage() || {};

    if (controlledLoadUsage2) {
      inputsArr.push({
        label: 'Controlled load 2 usage',
        id: 'controlled-load-usage-2',
        name: 'load2',
        index: 2,
        removable: true,
      });
    }
    if (controlledLoadUsage3) {
      inputsArr.push({
        label: 'Controlled load 3 usage',
        id: 'controlled-load-usage-3',
        name: 'load3',
        index: 3,
        removable: true,
      });
    }

    return inputsArr;
  });

  const addControlledLoadInput = () => {
    setInputs((prevState) => {
      const lastElement = prevState[prevState.length - 1];
      const newElementIndex = lastElement.index + 1;

      const newInput = {
        label: `Controlled load ${newElementIndex} usage`,
        id: `controlled-load-usage-${newElementIndex}`,
        name: `load${newElementIndex}`,
        index: newElementIndex,
        removable: true,
      };

      return [...prevState, newInput];
    });
  };

  const removeInputByName = (inputName: string) => {
    setInputs((prevState) =>
      prevState.filter((input) => input.name !== inputName)
    );
  };

  const isBelowInputLimit = inputs[inputs.length - 1].index < inputsLimit;

  const [initialFormValues] = useState(() => {
    const { controlledLoadUsage1, controlledLoadUsage2, controlledLoadUsage3 } =
      getControlledLoadUsage() || {};

    return {
      load1: controlledLoadUsage1?.toString() || '',
      load2: controlledLoadUsage2?.toString() || '',
      load3: controlledLoadUsage3?.toString() || '',
    };
  });

  const handleNext = (values: typeof initialFormValues) => {
    const { load1, load2, load3 } = values;

    const controlledLoadUsage: ControlledLoadUsageLsType = {
      controlledLoadUsage1: parseFloat(load1),
    };

    if (load2) {
      controlledLoadUsage.controlledLoadUsage2 = parseFloat(load2);
    }
    if (load3) {
      controlledLoadUsage.controlledLoadUsage3 = parseFloat(load3);
    }

    setControlledLoadUsage(controlledLoadUsage);

    navigateAndSetActiveStep(StepUrl.URL_SOLAR_INSTALLED);
  };

  const handleBack = () => {
    navigateAndSetActiveStep(StepUrl.URL_CONTROLLED_LOAD);
  };

  usePreloadPageData(StepUrl.URL_SOLAR_INSTALLED, StepUrl.URL_CONTROLLED_LOAD);

  return (
    <SEOLayout>
      <StepperLayout
        currentStage={2}
        currentStep={8}
        pageUrl={StepUrl.URL_CONTROLLED_LOAD_USAGE}
      >
        <div className="group my-3">
          <Formik
            initialValues={initialFormValues}
            onSubmit={(values) => {
              handleNext(values);
            }}
            validationSchema={ControlledLoadSchema}
          >
            {(props) => (
              <Form>
                <h2 className="my-2">Controlled load usage</h2>
                {inputs.map((input, index) => (
                  <InputGroupRemovable
                    className="my-2"
                    isRemovable={
                      index + 1 === inputs.length && !!input?.removable
                    }
                    key={input.name}
                    label={<Label htmlFor={input.id}>{input.label}</Label>}
                    input={
                      <Input
                        id={input.id}
                        name={input.name}
                        type="number"
                        unit="kWh"
                        controls
                        min={0.01}
                        max={10_000}
                        step={0.01}
                      />
                    }
                    onRemove={() => {
                      removeInputByName(input.name);
                      props.setFieldValue(input.name, '');
                    }}
                  />
                ))}
                {isBelowInputLimit && (
                  <Button
                    className="my-2"
                    type="button"
                    variant="link"
                    onClick={() => {
                      addControlledLoadInput();
                    }}
                  >
                    Add additional controlled load
                  </Button>
                )}
                <Button
                  className="mt-5"
                  type="submit"
                  disabled={props.values.load1 === ''}
                >
                  Continue
                </Button>
              </Form>
            )}
          </Formik>
          <div className="my-3">
            <BackButton text="Back" onClick={handleBack} />
          </div>
        </div>
      </StepperLayout>
    </SEOLayout>
  );
};

export default ControlledLoadUsage;
