import React from 'react';
import classNames from 'classnames';
// @ts-ignore
import { ReactComponent as BackArrowIcon } from '../../../assets/vectors/BackArrowIcon.svg';

const style = require('./BackButton.module.scss');

type BackButtonProps = {
  text: string;
  [otherProps: string]: any;
};

const BackButton = (props: BackButtonProps) => {
  const { text, className = '', ...rest } = props;

  return (
    <div {...rest} className={classNames(style.wrapper, className)}>
      <BackArrowIcon width={13} height={11} />
      <button className={style.text} type="button" tabIndex={0}>
        {text}
      </button>
    </div>
  );
};

export default BackButton;
