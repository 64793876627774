import React from 'react';
import Button from '../Button';

const style = require('./InputGroupRemovable.module.scss');

type InputGroupRemovableProps = {
  label: React.ReactNode;
  input: React.ReactNode;
  isRemovable: boolean;
  onRemove?: Function;
  className?: string;
  [otherProps: string]: any;
};

const InputGroupRemovable = (props: InputGroupRemovableProps) => {
  const { label, input, isRemovable, onRemove, className, ...rest } = props;

  return (
    <div {...rest} className={`${className} ${style.removableInputGroup}`}>
      {isRemovable ? (
        <>
          <div className={style.removableInputLabelWrapper}>
            {label}
            <Button
              type="button"
              variant="link"
              className={style.removeInputButton}
              onClick={onRemove}
            >
              Remove
            </Button>
          </div>
          <div className={style.removableInputWrapper}>
            {input}
            <Button
              type="button"
              variant="link"
              className={style.removeInputButton}
              onClick={onRemove}
            >
              Remove
            </Button>
          </div>
        </>
      ) : (
        <>
          {label}
          {input}
        </>
      )}
    </div>
  );
};

InputGroupRemovable.defaultProps = {
  className: '',
  onRemove: () => {},
};

export default InputGroupRemovable;
